<template>
  <div class="final">
    <button @click="$refs.download.click()">
      Télécharger la fiche Gestion Libre
    </button>
    <a
      ref="download"
      href="/Fiche_pratique_SOGERETRAITE_Investir_en_Gestion_Libre.pdf"
      download
    ></a>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["score", "result"])
  }
};
</script>
