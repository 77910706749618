<template>
  <div class="quizz">
    <span class="points"
      ><span class="score">{{ score }}</span>
      {{ score !== 1 ? "points" : "point" }}</span
    >
    <h1 ref="question" :key="'title'">{{ question.title }}</h1>
    <div ref="buttons" class="quizz-list">
      <QuizzButton
        v-for="(answer, i) in question.answers"
        :key="i"
        @click="handleAnswer(answer)"
        :answer="answer"
        :index="i"
      />
    </div>
    <img
      ref="illu"
      class="illustration"
      :src="require('@/assets/images/illustrations/' + question.illustration)"
      :alt="question.title"
    />
  </div>
</template>
<script>
import QuizzButton from "@/components/QuizzButton";
import { mapGetters, mapActions } from "vuex";
import gsap from "gsap";
export default {
  components: {
    QuizzButton
  },
  computed: {
    ...mapGetters(["score"])
  },
  data() {
    return {
      question: {
        title: "Vous souhaitez suivre votre épargne retraite",
        step_id: 5,
        illustration: "saving.svg",
        answers: [
          {
            name: "Très régulièrement",
            score: 40
          },
          {
            name: "Régulièrement",
            score: 30
          },
          {
            name: "Ponctuellement",
            score: 20
          },
          {
            name: "Rarement ou jamais",
            score: 0
          }
        ]
      }
    };
  },
  mounted() {
    gsap.to(this.$refs.illu, { opacity: 1, duration: 1 });
  },
  methods: {
    ...mapActions(["updateScore", "updateAnswers", "updateSteps"]),

    async handleAnswer(answer) {
      await this.updateScore(answer.score);
      await this.updateAnswers(answer);
      await this.updateSteps(this.question.step_id);
      this.$router.push("final");
    }
  }
};
</script>
