<template>
  <div class="results">
    <h1>Résultat de votre test</h1>
    <h3>
      Vous totalisez <span>{{ score }}</span> points
    </h3>
    <p>{{ result }}</p>
    <pre><code>
            {{answers}}
            </code></pre>
    <ul>
      <li v-for="(answer, i) in answers" :key="i">
        {{ answer.name }} (+{{ answer.score }}
        {{ answer.score > 1 ? "points" : "point" }})
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["score", "answers", "profils", "result"])
  }
};
</script>
