<template>
  <div v-if="isMounted" class="container">
    <div class="quizz" v-show="routeStep === 'quizz'">
      <div
        class="progression"
        :style="'width:' + (steps.length / questions.length) * 100 + '%'"
      ></div>
      <!-- <span class="points"
        ><span class="score">{{ score }}</span>
        {{ score !== 1 ? "points" : "point" }}</span
      > -->

      <span
        @click="handleBack"
        class="back"
        v-if="step > 1"
        :key="isAnimationOver"
        >Retour</span
      >
      <div class="header">
        <h1 ref="title">{{ current.title }}</h1>
        <!-- <div class="title"> -->
        <!-- <h1 ref="question-first">{{ title.first }}</h1>
        <h1 ref="question-second">
          {{ title.second }}
        </h1> -->
        <!-- </div> -->
        <span class="subtitle" ref="subtitle" v-if="current.type === 'multiple'"
          >(plusieurs réponses possibles)</span
        >
        <div class="underline" ref="underline" v-if="false"></div>
      </div>

      <div ref="buttons" class="quizz-list">
        <div
          class="button-container"
          ref="animation-button"
          v-for="(answer, i) in current.answers"
          :key="answer.isSelected + '' + i"
        >
          <QuizzButton
            @click="e => handleAnswer(answer, i, e)"
            @clickUnique="handleUniqueAnswer(answer)"
            :answer="answer"
            :index="i"
            :selected="
              multipleAnswers.find(a => a.name === answer.name) ? true : false
            "
          />
        </div>
      </div>
      <div
        v-if="isMultiple"
        ref="validate-multiple"
        class="validate-multiple"
        @click="validateMultipleAnswers"
      >
        <span>Valider</span>
      </div>

      <img
        ref="illu"
        class="illustration"
        :src="require('@/assets/images/illustrations/' + current.illustration)"
        :alt="current.title"
      />
    </div>
    <div class="profil" v-show="routeStep === 'profil'">
      <div class="player-container" ref="player-container">
        <Player
          v-show="videoStep === 'video'"
          :video="currentProfil.video"
          size="full"
          @ended="handleFinal"
          :playinline="true"
          ref="player"
        />
      </div>
      <Final v-if="showFinal"></Final>
    </div>
    <div
      class="profil-transition"
      ref="profil-transition"
      v-show="routeStep === 'profil-transition'"
    >
      <span @click="goToFinalVideo" class="button"
        >Découvrez votre profil en vidéo</span
      >
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import gsap from "gsap";
import QuizzButton from "@/components/QuizzButton";
import Player from "@/components/Player";
import Final from "@/views/Final";

// import SplitType from "split-type";
import { bus } from "../main";
export default {
  components: {
    QuizzButton,
    Player,
    Final
  },
  data() {
    return {
      isMounted: false,
      isMuted: false,
      isAnimationOver: false,
      timeline: null,
      progression: 0,
      quizz: {},
      showFinal: false,
      routeStep: "quizz",
      videoStep: "intro",
      playerVideo: null,
      questions: [
        // {
        //   title: "Vous partez à la retraite dans ...",
        //   step_id: 1,
        //   illustration: "time.svg",
        //   answers: [
        //     {
        //       name: "Plus de 35 ans",
        //       score: 40
        //     },
        //     {
        //       name: "Entre 35 et 25 ans",
        //       score: 30
        //     },
        //     {
        //       name: "Entre 25 et 15 ans",
        //       score: 20
        //     },
        //     {
        //       name: "Entre 15 et 5 ans",
        //       score: 10
        //     },
        //     {
        //       name: "Moins de 5 ans",
        //       score: -25
        //     }
        //   ]
        // },
        {
          title: "Vos connaissances en matière de gestion financière sont",
          step_id: 1,
          pointCondition: 0,
          illustration: "finance-knowledge.svg",
          answers: [
            {
              name: "Très limitées",
              score: 0
            },
            {
              name: "Assez génériques",
              score: 10
            },
            {
              name: "Bonnes",
              score: 20
            }
          ]
        },
        {
          title: "Vous recherchez une évolution de votre épargne retraite",
          step_id: 2,
          pointCondition: 0,
          illustration: "evolution.svg",
          answers: [
            {
              name: "Modeste et plutôt régulière",
              score: 10
            },
            {
              name: "Modérée avec un risque de perte en capital limité",
              score: 15
            },
            {
              name:
                "Significative tout en acceptant des fluctuations et un risque de perte en capital",
              score: 25
            }
          ]
        },
        {
          title: "Vous avez déjà investi sur",
          step_id: 3,
          pointCondition: 0,
          illustration: "investments.svg",
          type: "multiple",
          answers: [
            {
              name: "Des supports monétaires ou des supports en euro",
              score: 10
            },
            {
              name: "Des supports obligataires ou actions",
              score: 10
            },
            {
              name:
                "D’autres supports complexes disponibles sur des PEA, Comptes-titres...",
              score: 10
            },
            {
              name: "Aucun, je ne sais pas",
              score: 0,
              type: "unique"
            }
          ]
        },
        {
          title:
            "En cas de baisse importante des marchés financiers en tant qu’investisseur, vous",
          step_id: 4,
          pointCondition: 0,
          illustration: "market-fall.svg",
          answers: [
            {
              name: "Réinvestissez sur les marchés financiers",
              score: 20
            },
            {
              name: "Attendez que l'orage passe",
              score: 10
            },
            {
              name:
                "Mettez l’ensemble de votre épargne sur des supports plus sécurisés",
              score: 0
            }
          ]
        },
        {
          title: "Vous souhaitez suivre votre épargne retraite",
          step_id: 5,
          pointCondition: 75,
          illustration: "saving.svg",
          answers: [
            {
              name: "Très régulièrement",
              score: 40
            },
            {
              name: "Régulièrement",
              score: 30
            },
            {
              name: "Ponctuellement",
              score: 20
            },
            {
              name: "Rarement ou jamais",
              score: 0
            }
          ]
        }
      ],
      multipleAnswers: []
    };
  },
  created() {
    if (!Array.prototype.find) {
      Array.prototype.find = function(predicate) {
        if (this == null) {
          throw new TypeError(
            "Array.prototype.find called on null or undefined"
          );
        }
        if (typeof predicate !== "function") {
          throw new TypeError("predicate must be a function");
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
          value = list[i];
          if (predicate.call(thisArg, value, i, list)) {
            return value;
          }
        }
        return undefined;
      };
    }
  },
  mounted() {
    if (this.step > this.questions.length) this.$router.push("/");
    else {
      this.isMounted = true;

      setTimeout(() => {
        this.enterAnimation();
        // this.$refs.audio.volume = 1;
      });
    }
    if (this.step && this.step !== 1) {
      this.$router.push("/quizz/1");
    }
  },
  computed: {
    ...mapGetters(["score", "answers", "steps", "profils"]),
    currentProfil() {
      let result = {};
      if (this.score <= 40) result = this.profils[0];
      else if (this.score > 40 && this.score < 70) result = this.profils[1];
      else if (this.score >= 70 && this.score < 125) result = this.profils[2];
      else if (this.score >= 125) result = this.profils[3];
      return result;
    },
    step() {
      return parseInt(this.$route.params.id);
    },
    current() {
      return this.questions[this.step - 1];
    },
    complete() {
      return this.$route.params.id - 1 === this.questions.length;
    },
    isMultiple() {
      return this.current.type === "multiple";
    }
  },
  methods: {
    ...mapActions(["updateScore", "updateAnswers", "updateSteps"]),
    async handleAnswer(quizz) {
      if (!this.isAnimationOver) return;
      if (this.isMultiple) {
        if (
          this.multipleAnswers[0] &&
          this.multipleAnswers[0].type === "unique"
        )
          this.multipleAnswers = [];
        let alreadySelected = this.multipleAnswers.findIndex(
          a => a.name === quizz.name
        );
        if (alreadySelected > -1)
          this.multipleAnswers.splice(alreadySelected, 1);
        else this.multipleAnswers.push(quizz);
      } else {
        this.quizz = quizz;
        await this.updateScore(quizz.score);
        await this.updateAnswers(quizz);
        await this.updateSteps(this.step);
        this.leaveAnimation();
        if (
          this.step < this.questions.length &&
          this.score >= this.questions[this.step]?.pointCondition
        ) {
          setTimeout(() => {
            this.$router.push("/quizz/" + (this.step + 1));
          }, 1000);
        } else {
          setTimeout(() => {
            this.routeStep = "profil-transition";
            gsap.fromTo(
              this.$refs["profil-transition"],
              { opacity: 0 },
              { opacity: 1, duration: 1 }
            );
            bus.$emit("fadeTrack");
          }, 1000);
        }
      }
    },
    handleUniqueAnswer(answer) {
      if (!this.isAnimationOver) return;
      let alreadySelected = this.multipleAnswers.findIndex(
        a => a.name === answer.name
      );
      if (alreadySelected > -1) this.multipleAnswers = [];
      else this.multipleAnswers = [answer];
    },
    async validateMultipleAnswers() {
      if (!this.multipleAnswers.length || !this.isAnimationOver) return;
      let score = this.multipleAnswers.reduce(
        (acc, current) => acc + current.score,
        0
      );

      await this.updateScore(score);
      await this.updateAnswers(this.multipleAnswers);
      await this.updateSteps(this.step);
      this.leaveAnimation();
      if (
        this.step < this.questions.length &&
        this.score >= this.questions[this.step]?.pointCondition
      ) {
        setTimeout(() => {
          this.$router.push("/quizz/" + (this.step + 1));
        }, 1000);
      } else {
        this.$refs.player.handlePlay();
        bus.$emit("stopTrack");
        this.routeStep = "profil";
        this.videoStep = "video";
        gsap.fromTo(
          this.$refs["player-container"],
          { opacity: 0, delay: 1 },
          { opacity: 1, duration: 2, delay: 1 }
        );
      }
    },
    handleBack() {
      if (!this.isAnimationOver) return;
      this.$store.commit("PREVIOUS_STEP");
      this.leaveAnimation();
      setTimeout(() => {
        this.$router.push("/quizz/" + (this.step - 1));
      }, 1000);
    },
    leaveAnimation() {
      gsap.to(this.$refs.illu, { opacity: 0, duration: 1 });
      gsap.to(this.$refs.title, {
        opacity: 0,
        x: 700,
        duration: 1
      });
      if (this.$refs["subtitle"])
        gsap.to(this.$refs["subtitle"], {
          opacity: 0,
          x: -700,
          duration: 1
        });
      gsap.to(this.$refs["animation-button"], {
        opacity: 0,
        x: -70,
        stagger: 0.2
      });
      gsap.to(this.$refs["illu"], {
        opacity: 0,
        duration: 1
      });
      if (this.$refs["validate-multiple"])
        gsap.to(this.$refs["validate-multiple"], {
          opacity: 0,
          duration: 1
        });
      this.isAnimationOver = false;
    },
    enterAnimation() {
      gsap.to(this.$refs.illu, { opacity: 1, duration: 2, delay: 0 });
      gsap.to(this.$refs.title, { opacity: 1, duration: 0, x: 0 });
      gsap.to(this.$refs["animation-button"], {
        opacity: 1,
        duration: 0,
        x: 0
      });
      gsap.from(this.$refs.title, {
        opacity: 0,
        x: -700,
        delay: 0,
        duration: 1
      });
      gsap.from(this.$refs["animation-button"], {
        opacity: 0,
        x: 70,
        stagger: 0.2
      });
      if (this.$refs["subtitle"])
        gsap.from(this.$refs["subtitle"], {
          opacity: 0,
          x: -700,
          delay: 0,
          duration: 1
        });

      if (this.$refs["validate-multiple"])
        gsap.from(this.$refs["validate-multiple"], {
          opacity: 0,
          x: -700,
          delay: 0,
          duration: 1
        });
      setTimeout(() => {
        this.isAnimationOver = true;
      }, 1000);
    },
    goToFinalVideo() {
      gsap.to(this.$refs["profil-transition"], { opacity: 0, duration: 1 });
      this.$refs.player.handlePlay();
      setTimeout(() => {
        this.routeStep = "profil";
        bus.$emit("stopTrack");
        this.videoStep = "video";
        gsap.fromTo(
          this.$refs["player-container"],
          { opacity: 0, delay: 1 },
          { opacity: 1, duration: 2, delay: 1 }
        );
      }, 1000);
    },
    handleFinal() {
      if (this.currentProfil.slug === "gestion-libre") this.showFinal = true;
    }
  },
  watch: {
    step: function() {
      this.multipleAnswers = [];
      setTimeout(() => {
        this.enterAnimation();
      });
    }
  }
};
</script>
