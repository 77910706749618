<template>
  <button
    ref="button"
    class="button"
    :class="selected ? 'button-selected' : null"
    @click="handleClick"
    :key="Date.now()"
  >
    {{ answer.name }}
  </button>
</template>
<script>
// import gsap from "gsap";
export default {
  props: ["answer", "index", "selected"],
  data: () => ({
    isSelected: false
  }),
  mounted() {
    this.$refs.button.blur();
    // this.animate();
  },
  methods: {
    // animate() {
    //   gsap.from(this.$refs.button, {
    //     opacity: 0,
    //     x: 70,
    //     delay: this.index * 0.4
    //   });
    // },
    handleClick(e) {
      if (this.answer.type === "unique") this.$emit("clickUnique");
      else this.$emit("click", e);
      this.isSelected = true;
    }
  },
  watch: {
    answer: {
      handler: function() {
        // this.animate();
      }
    },
    deep: true
  }
};
</script>
