<template>
  <div class="introduction">
    <Player
      video="sga_intro.mp4"
      size="full"
      :playinline="true"
      @ended="begin"
      :autoplay="true"
    />
  </div>
</template>
<script>
import Player from "@/components/Player";
export default {
  components: {
    Player
  },
  methods: {
    begin() {
      setTimeout(() => {
        this.$router.push("/quizz/1");
      }, 1000);
    }
  }
};
</script>
