<template>
  <transition name="fade" mode="out-in">
    <!-- <div class="close" @click="showFinal = false">&times;</div> -->
  </transition>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    playing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentView: "intro",
      showFinal: false
    };
  },
  mounted() {
    this.showFinal = true;
  },
  computed: {
    ...mapGetters(["profils", "score"]),
    current() {
      let result = {};
      if (this.score <= 40) result = this.profils[0];
      else if (this.score > 40 && this.score < 70) result = this.profils[1];
      else if (this.score >= 70 && this.score < 125) result = this.profils[2];
      else if (this.score >= 125) result = this.profils[3];
      return result;
    }
  },
  methods: {
    next() {
      setTimeout(() => {
        this.showFinal = true;
      }, 1500);
    }
  }
};
</script>
